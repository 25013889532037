// 岗位职位
import {
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
  downloadRequest
} from "@/libs/axios";

// 职位列表
export const getListPage = params => {
  return getRequest("/yethan/register/unitsPosition/listPage", params);
};
// 添加职位
export const addPosition = params => {
  return postRequest("/yethan/register/unitsPosition", params);
};
// 修改职位
export const editPosition = params => {
  return putRequest("/yethan/register/unitsPosition", params);
};
// 删除职位
export function deletePosition(sid) {
  return deleteRequest("/yethan/register/unitsPosition/" + sid);
}
// 更新启用状态
export const updateUnitsPositionQyzt = params => {
  return patchRequest("/yethan/register/unitsPosition/updateUnitsPositionQyzt", params);
};
// 职位详情
export const getDetail = params => {
  return getRequest("/yethan/register/unitsPosition/" + params);
}


// 编辑职位和科目关联
export const editPositionSubject = params => {
  return putRequest("/yethan/register/positionSubject", params);
}
// 添加职位和科目关联
export const addPositionSubject = params => {
  return postRequest("/yethan/register/positionSubject", params);
}
// 删除职位和科目关联
export function deletePositionSubject(sid) {
  return deleteRequest('/yethan/register/positionSubject/' + sid);
}

// 导出excel
export const exportPositionExcel = params => {
  return downloadRequest("/yethan/register/unitsPosition/exportExcel", params);
};
// 导出dbf
export const exportPositionDbf = params => {
  return downloadRequest("/yethan/register/unitsPosition/exportDbf", params);
};