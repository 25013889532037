<!-- 职位 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }

  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/ .el-input__icon {
    line-height: inherit;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import BaseHeader from "@/components/base-header";
  import {
    getListPage,
    addPosition,
    editPosition,
    deletePosition,
    updateUnitsPositionQyzt,
    getDetail,
    editPositionSubject,
    addPositionSubject,
    deletePositionSubject,
  } from "@/api/admin/base/position.js"
  import {
    getSubListEasy
  } from "@/api/admin/base/subject.js"
  import {
    orgEasyList,
    orgUnitsList,
    orgDepartmentList
  } from "@/api/admin/base/org.js"
  import {
    getExamEasy,
    setNowExam,
    getNowExam
  } from "@/api/admin/exam/examRecord.js"
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      BaseHeader,
    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "职位库",
            active: true
          }
        ],
        exportTitle: "考试职位库导出",
        importTitle: "考试职位库导入",
        checked: true,
        defaultvalue: 1,
        currentpage: 1,
        showmodal: false,
        showmodal2: false,
        showmodal3: false,
        examDiv: false,
        disabled: false,
        ksbmbh: "",
        ksmc: "",
        ksnf: "",
        examParam: {
          ksnf: new Date().getFullYear(),
          ksmc: ''
        },
        dialogTitle: "添加职位/岗位",
        module: "KSBMZWK",
        tableList: [],
        orgList: [],
        orgUnitsList: [],
        orgDepartmentList: [],
        options: [],
        kmmc: "",
        kmdm: "",
        flbm: "",
        list: [],
        loading: false,
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          ksbmbh: '',
        },
        detailForm: {
          ksmc: "",
          bmmc: "",
          zwmc: "",
          jhrs: "",
          bz: "",
        },
        form: {
          kmdm: "",
          zwdm: "",
        },
        addForm: {},
        select: {
          ksnf: new Date().getFullYear(),
          ksbmbh: "",
          keyWord: "",
          keyParam: "zwmc",
          jgdm: "",
          dwdm: "",
        },
        tabList2: [],
        years: [],
        examList: [],
        exam: [],
      };
    },
    methods: {
      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deletePosition(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 获取数据列表
      getList() {
        this.pageData.ksbmbh = this.ksbmbh
        getListPage(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.tableList.sfbz = this.tableList.sfbz / 100
            this.pageData.total = res.total
          }
        })
      },
      // 改变启用状态
      changeQyzt(obj) {
        let {
          sid,
          qyzt
        } = obj
        updateUnitsPositionQyzt({
          sid: sid,
          qyzt: qyzt
        }).then()
      },
      // 添加类别
      addMaintype() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        formDate.sfbz = formDate.sfbz * 100
        addPosition(formDate).then(res => {
          if (res.status) {
            this.success()
          }

        })
      },
      editMaintype() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        formDate.sfbz = formDate.sfbz * 100,
          formDate.sid = this.addForm.sid
        editPosition(formDate).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },

      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!'
        })
        this.showmodal = false;
        this.getList()
      },
      addItem() {
        this.showmodal = true
        this.dialogTitle = "添加职位/岗位"
        this.addForm = {}
        this.addForm.qyzt = true
        this.addForm.ksnf = this.ksnf
        this.addForm.ksmc = this.ksmc
        this.addForm.ksbmbh = this.ksbmbh
        this.getBm(this.module, "zw").then(e => {
          this.addForm.zwdm = e
          console.log(this.addForm.zwdm)
        })

      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.addForm.sfbz = this.addForm.sfbz / 100
        this.showmodal = true
        this.dialogTitle = "修改职位/岗位"
        this.disabled = true
      },
      submit() {
        if (this.addForm.sid) {
          this.editMaintype()
        } else {
          this.addMaintype()
        }
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
      },
      ShowDetails(sid) {
        getDetail(sid).then(res => {
          if (res.status) {
            this.detailForm = res.data
            this.tabList2 = res.data.positionSubjectList
            this.getSubject(this.flbm)
          }

        })
      },
      // 添加选科科目
      addClassSub() {
        addPositionSubject({
          kmdm: this.kmdm,
          zwdm: this.detailForm.zwdm
        }).then(res => {
          if (res.status) {
            this.ShowDetails(this.detailForm.sid)
          }
        })
      },
      // 删除选科科目
      deleteClassSub(sid) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deletePositionSubject(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.ShowDetails(this.detailForm.sid)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      editClassSub(item) {
        console.log(item)
        let copyData = JSON.parse(JSON.stringify(item))
        this.form = copyData
        this.form.sfbz = copyData.sfbz / 100
      },
      subClass(k) {
        let from = JSON.parse(JSON.stringify(k))
        from.zwdm = this.detailForm.zwdm
        from.sfbz = k.sfbz * 100
        editPositionSubject(from).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '数据修改成功'
            });
          }
          this.showmodal3 = false
          this.ShowDetails(this.detailForm.sid)
        })
      },
      // 获取机构列表
      getOrgList() {
        orgEasyList().then(res => {
          if (res.status) {
            this.orgList = res.data
          }
        })
      },
      // 获取单位列表
      getOrgUnitsList(params) {
        orgUnitsList({
          jgdm: params
        }).then(res => {
          if (res.status) {
            this.orgUnitsList = res.data
          }
        })
      },
      // 获取部门列表
      getOrgDepartmentList() {
        orgDepartmentList().then(res => {
          if (res.status) {
            this.orgDepartmentList = res.data
          }
        })
      },

      //搜索显示职位相关科目
      getSubject(params) {
        getSubListEasy({
          flbm: params
        }).then(res => {
          if (res.status) {
            this.options = res.data
          }
        })
      },

      getjgmc() {
        let val = this.addForm.jgdm
        let obj = this.orgList.find(k => {
          return k.jgdm === val
        })
        this.addForm.jgmc = obj.jgmc
        this.getOrgUnitsList(val)
      },
      getdwmc() {
        let val = this.addForm.dwdm
        let obj = this.orgUnitsList.find(k => {
          return k.dwdm === val
        })
        this.addForm.dwmc = obj.dwmc
      },
      getbmmc() {
        let val = this.addForm.bmdm
        let obj = this.orgDepartmentList.find(k => {
          return k.bmdm === val
        })
        this.addForm.bmmc = obj.bmmc
      },
      searchClick() {
        this.pageData.pageNum = 1
        this.getlistByParam()
      },
      // 条件查询
      getlistByParam() {
        this.select.ksbmbh = this.ksbmbh
        this.select.ksnf = this.ksnf
        getListPage({
          ...this.pageData,
          ...this.select
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total;
          }
        })
      },
      //获取当前年份开始的前后几年
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      // 获取考试列表
      getExamList() {
        getExamEasy(this.examParam).then(res => {
          if (res.status) {
            this.examList = res.data
            if (this.ksbmbh == "") {
              this.ksbmbh = res.data[0].ksbmbh
              this.ksmc = res.data[0].ksmc
              this.getList()
            }
          }
        })
      },
      // 切换考试
      changeExam(info) {
        this.ksbmbh = info.ksbmbh
        this.ksmc = info.ksmc
        this.examDiv = false
        this.tableList = []
        setNowExam(this.ksbmbh)
        this.getList()
      },
      //获取切换的考试
      getLastExam() {
        getNowExam().then(res => {
          if (res.status) {
            this.ksbmbh = res.data.ksbmbh
            this.ksmc = res.data.ksmc
            this.ksnf = res.data.ksnf
            this.flbm = res.data.flbm
            this.getList()
          }
        })
      },
      uploadSuccess() { // 数据导入之后刷新列表
        this.getList()
      }

    },
    mounted() {
      this.getLastExam()
      this.getExamList()
      this.getYear()
      this.getOrgList()
      this.getOrgUnitsList()
      this.getOrgDepartmentList()



    },
    created() {

    }

  };
</script>

<template>
  <Layout>
    <BaseHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="page-title-box" style="padding-bottom: 10px;">
              <h4 class="mb-2 text-dark">{{ksmc}}<span class="font-blue ml-2 " v-b-modal.showmodal>切换考试</span></h4>
            </div>
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <el-select class=" mr-2 blueBorder" clearable style="width:12%;" placeholder="请选择机构名称"
                  v-model="select.jgdm" size="small" @change="getOrgUnitsList(select.jgdm)">
                  <el-option :value="item.jgdm" v-for="item in orgList" :key="item.value" :label="item.jgmc">
                    {{item.jgmc}}
                  </el-option>
                </el-select>
                <el-select class=" mr-2 blueBorder" clearable style="width: 10%;" placeholder="请选择单位名称"
                  v-model="select.dwdm" size="small">
                  <el-option :value="item.dwdm" v-for="item in orgUnitsList" :key="item.value" :label="item.dwmc">
                    {{item.dwmc}}
                  </el-option>
                </el-select>
                <input placeholder="关键字" v-model="select.keyWord" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加职位</b-button>
                <button type="button" class="btn btn-outline-secondary flexList"
                  @click="$importModal().show({title: importTitle, module:module,success:uploadSuccess})"><i
                    class="iconfont icon-a-stLine-cloud-upload-lCopy mr-2 font-size-20"></i>{{importTitle}}</button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title:exportTitle, type: 'xlsx', module:module,condition:select})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:select})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width:3%">序号</th>
                    <th style="width: 15%;">所属考试</th>
                    <th style="width: 10%;">所属机构</th>
                    <th style="width: 10%;">所属单位</th>
                    <th style="width:8%">职位代码</th>
                    <th style="width:10%">职位名称</th>
                    <th style="width:5%" class="text-center">计划人数</th>
                    <th style="width:8%" class="text-center">最大报名人数</th>
                    <th style="width:5%">是否启用</th>
                    <th style="width:5%" class="text-center">审核状态</th>
                    <th style="width:10%">考试科目</th>
                    <th style="width: 6%;">修改人</th>
                    <th style="width: 10%;">修改时间</th>
                    <th style="width: 5%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index ) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.ksmc}}</td>
                    <td>{{obj.jgmc}}</td>
                    <td>{{obj.dwmc}}</td>
                    <td> {{obj.zwdm}}</td>
                    <td>
                      <div style="cursor: pointer;" class="font-blue" v-b-modal.positionDetails
                        @click="ShowDetails(obj.sid)">{{obj.zwmc}}</div>
                    </td>
                    <td class="text-center">{{obj.jhrs}}</td>
                    <td class="text-center">{{obj.zdbmrs}}</td>
                    <td>
                      <b-form-checkbox v-model="obj.qyzt" switch class=" switch-check" @change="changeQyzt(obj)">
                      </b-form-checkbox>
                    </td>
                    <td class="text-center tab-icon">
                      <i class="iconfont icon-a-ze-checkedCopy2  text-info" v-if="obj.shzt==1"></i>
                      <i class="iconfont icon-ze-clear text-danger" v-if="obj.shzt==2"></i>
                      <i class="iconfont icon-icon_minus-circled " v-if="obj.shzt==0"></i>
                    </td>
                    <td><span v-if="tabList2.length>0">已添加</span><span v-else>未添加</span></td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{timeSlice(obj.modifyTime)}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                        @click="editItem(obj)"></i><i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.sid,obj.zwmc)"></i></td>
                  </tr>

                </tbody>
              </table>

            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!--添加职位/岗位-->
    <b-modal id="addPosition" v-model="showmodal" centered :title="this.dialogTitle" title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>考试年份：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" disabled v-model="addForm.ksnf" maxlength="50"
                  class="form-control w-100 h30 " />


              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属考试：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" disabled v-model="addForm.ksmc" maxlength="50"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属机构：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.jgdm" @change="getjgmc">
                  <option value="" disabled>请选择所属机构</option>
                  <option :value="item.jgdm" v-for="item in orgList" :key="item.value" :label="item.jgmc">{{item.jgmc}}
                  </option>
                </select>
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属单位：</label>
              <div class="col-sm-10  p-0">
                <select class="form-control form-select w-100" v-model="addForm.dwdm" size="small" @change="getdwmc">
                  <option value="" disabled>请选择所属单位</option>
                  <option :value="item.dwdm" v-for="item in orgUnitsList" :key="item.value" :label="item.dwmc">
                    {{item.dwmc}}
                  </option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属部门：</label>
              <div class=" col-sm-10 p-0 flexList">
                <select class="w-100 form-control form-select" v-model="addForm.bmdm" size="small" @change="getbmmc">
                  <option value="" disabled>请选择所属部门</option>
                  <option :value="item.bmdm" v-for="item in orgDepartmentList" :key="item.value" :label="item.bmmc">
                    {{item.bmmc}}
                  </option>
                </select>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>职位编码：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" v-model="addForm.zwdm" :disabled="disabled" placeholder="职位编码,预置可修改"
                  maxlength="20" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>职位名称：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="50" v-model="addForm.zwmc" placeholder="请输入职位名称"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>计划人数：</label>
              <div class="col-sm-10 p-0 flexList">
                <b-form-input value="1" type="text" v-model="addForm.jhrs" class="w-100 h30" placeholder="计划人数">
                </b-form-input>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>报名人数：<br>
                (最大)</label>
              <div class="col-sm-10 p-0 flexList">
                <b-form-input value="1" type="text" v-model="addForm.zdbmrs" class="w-100 h30" placeholder="最大报名人数">
                </b-form-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>收费标准：</label>
              <div class="col-sm-10 p-0 flexList">
                <b-form-input value="500" type="text" v-model="addForm.sfbz" class="w-100 h30" placeholder="收费标准">
                </b-form-input>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">是否启用：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2 w-15"><input type="radio" name="exampleRadios" id="exampleRadios1"
                    value=true v-model="addForm.qyzt" class="form-check-input"><label for="exampleRadios1"
                    class="form-check-label">启用
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="exampleRadios" id="exampleRadios1" value=false
                    v-model="addForm.qyzt" class="form-check-input"><label for="exampleRadio1"
                    class="form-check-label">不启用</label></div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>备注说明：</label>
              <div class="col-sm-10 p-0 flexList">
                <textarea rows="3" cols="" maxlength="255" v-model="addForm.bz" class="form-control"
                  placeholder="多行输入"></textarea>

              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 职位/岗位详情 -->
    <b-modal id="positionDetails" v-model="showmodal2" centered title="职位/岗位详情" title-class="font-18" hide-footer>
      <div>
        <el-form ref="detailForm" :model="detailForm">
          <el-form-item>
            <div class="check-distri mb-2 major-details"><label>所属考试：</label>
              <div class=" col-sm-10">
                <div class="line1">{{detailForm.ksmc}}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="check-distri mb-2 major-details"><label>所属部门：</label>
              <div class=" col-sm-10">
                <div class="line1">{{detailForm.bmmc}}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="check-distri mb-2 major-details"><label>职位名称：</label>
              <div class=" col-sm-10">
                <div class="line1">{{detailForm.zwmc}}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="check-distri mb-2 major-details"><label>计划人数：</label>
              <div class=" col-sm-10">
                <div class="line1"> {{detailForm.jhrs}}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="check-distri mb-2 major-details"><label>备注说明：</label>
              <div class=" col-sm-10">
                <div class="line1">{{detailForm.bz}}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3">
              <div class="col-sm-10 pl-0 flexList">
                <!-- <input type="text" name="" id="" value="" v-model="detailForm.kmmc"
                  placeholder="输入科目名称" class="form-control w-50 h30 " /> -->
                <el-select v-model="kmdm" filterable reserve-keyword placeholder="请输入科目名称" class="w-50 h30">
                  <el-option v-for="item in options" :key="item.value" :label="item.kmmc" :value="item.kmdm">
                  </el-option>
                </el-select>
                <b-button variant="info" class="flexList w-md ml-2 h30" @click="addClassSub"><i
                    class="iconfont icon-plus-circle mr-2" style="font-size: 24px;"></i>添加考试科目</b-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="check-distri mb-2 major-details"><label>考试科目</label>
              <div class=" col-sm-10">
              </div>
            </div>
          </el-form-item>
          <table class="table table-hover table-borderless ">
            <thead>
              <tr v-for="(item,index) in tabList2" :key="index">
                <td style="width: 12%;">科目{{index+1}} </td>
                <td class="font-blue">{{item.kmdm}}--{{item.kmmc}}</td>
                <td>{{item.sfbz/100}}</td>
                <td class="tab-icon" style="width: 5%;"><i class="iconfont icon-edit-two align-middle mr-1"
                    v-b-modal.editSubject @click="editClassSub(item)"></i><i
                    class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                    @click="deleteClassSub(item.sid)"></i>
                </td>
              </tr>

            </thead>
          </table>
        </el-form>
      </div>




    </b-modal>
    <!-- 科目修改弹窗 -->
    <b-modal id="editSubject" v-model="showmodal3" centered title="专业科目" title-class="font-18" hide-footer>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="科目代码">
          <el-input v-model="form.kmdm" disabled></el-input>
        </el-form-item>
        <el-form-item label="科目名称">
          <el-select v-model="form.kmmc" filterable class="w-100 h30">
            <el-option v-for="item in options" :key="item.value" :label="item.kmmc" :value="item.kmdm">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收费标准">
          <el-input v-model="form.sfbz"></el-input>
        </el-form-item>
      </el-form>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="subClass(form)">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal3 = false">取消</button>
      </div>
    </b-modal>
    <!-- 切换考试 -->
    <b-modal id="showmodal" v-model="examDiv" title="切换考试报名库" size="lg" centered title-class="font-18" hide-footer>
      <div class="flexList">
        <el-select class=" mr-2" style="width:20%;" placeholder="请选择考试年份" v-model="examParam.ksnf" size="small">
          <el-option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">
          </el-option>
        </el-select>
        <input placeholder="关键字" class="h30 form-control border-blue mr-2" v-model="examParam.ksmc" maxlength="50"
          style="width: 156px;" />
        <button type="button" class="btn btn-info h30 flexList mr-2" @click="getExamList"><i
            class="iconfont mr-2 icon-mb-search"></i>查询</button>
      </div>
      <div class="table-responsive border mt-3">
        <table class="table light-table table-hover ">
          <thead class="thead-light">
            <tr>
              <th> 序号</th>
              <th>年份</th>
              <th>考试名称</th>
              <th>选择 </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in examList" :key="item.sid">
              <td> {{ i+1 }} </td>
              <td> {{ item.ksnf }} </td>
              <td><a href="javascript:void(0)" @click="changeExam(item)" class="blue-font">{{ item.ksmc }}</a></td>
              <td>
                <b-button size="sm" variant="info" @click="changeExam(item)">切换</b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


    </b-modal>
    <!-- 弹窗结束-->
  </Layout>
</template>
